/* eslint-disable no-nested-ternary */
import FullCalendar from '@fullcalendar/react'; // => request placed at the top
//
import listPlugin from '@fullcalendar/list';
//
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
//
import { useEffect, useRef, useState } from 'react';
// @mui
import {
  Button,
  Card,
  Container,
  DialogTitle,
  Grid,
  Paper,
  styled,
  ToggleButtonGroup,
  ToggleButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

// hooks
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import GoogleSignInButton from 'src/components/GoogleSignBurron';
import EmptyContent from 'src/components/EmptyContent';
import LeaveListDetail from 'src/sections/@dashboard/calendar/LeaveListDetail';
import { setCount } from 'src/redux/actions/reviewCount';
import { CalendarForm, CalendarStyle, CalendarToolbar } from 'src/sections/@dashboard/calendar';

import AxiosAll from 'src/services/axiosAll';
import useResponsive from '../../hooks/useResponsive';
import useSettings from '../../hooks/useSettings';

// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import { DialogAnimate } from '../../components/animate';
import AddMeeting from './AddMeeting';
import ApplyDialogue from './ApplyLeaveDialogue';

// sections
// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export default function Leaves() {
  const { themeStretch } = useSettings();
  const isDesktop = useResponsive('up', 'sm');
  const calendarRef = useRef(null);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState('dayGridMonth');
  const [openModel, setOpenModel] = useState(false);
  const [openApply, setOpenApply] = useState(false);
  const [openAddMeet, setOpenAddMeet] = useState(false);
  const [meetData, setMeetData] = useState({});
  const [events, setEvents] = useState([]);
  const [editData, setEditData] = useState({});
  const [colorData, setColorData] = useState([]);
  const state = useLocation();
  console.log('stttt', state?.state?.isBackButtonClicked);
  // const { isBackButtonClicked } = state;

  // const { isBackButtonClicked } = location.state || {};
  // console.log('back', isBackButtonClicked);
  const [isLogShow, setIsLogShow] = useState('calender');
  // const [isLogShow, setIsLogShow] = useState(!isBackButtonClicked ? 'calender' : 'list');
  const [editMeet, setEditMeet] = useState(false);
  const [isToken, setIsToken] = useState(false);
  const [leaves, setLeaves] = useState();

  const currentYear = new Date().getFullYear();
  const currentdate = `${currentYear}-01-01`;

  const [startDate, setStartDate] = useState(currentdate);
  const [endDate, setEndDate] = useState('');
  console.log('endDate', endDate);
  const [isRedirected, setIsRedirected] = useState(false);
  const nav = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get redux state
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const isAdmin = userDetails?.user?.role_id === 1;
  const isTeam = userDetails?.user?.role_id === 3;

  useEffect(() => {
    setIsLogShow(state?.state?.isBackButtonClicked ? 'list' : 'calender');
  }, [state?.state?.isBackButtonClicked]);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = 'dayGridMonth';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [isDesktop]);
  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };
  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };
  const handleSelectRange = (arg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }
    // dispatch(selectRange(arg.start, arg.end));
  };

  // Handlled open different dialogue boxes
  const handleSelectEvent = async (arg) => {
    if (arg.event._def.extendedProps.type === 'leave') {
      if (arg.event._def.extendedProps.lead === userDetails?.user?.id) {
        nav(`/leaves/${arg?.event?._def?.publicId}`);
        console.log('I am lead');
      } else {
        console.log('I am not lead');
      }
    } else if (arg.event._def.extendedProps.type === 'meeting') {
      await setMeetData(arg.event);
      setEditMeet(true);
      setOpenAddMeet(true);
    } else {
      setOpenModel(true);
      setEditData(arg.event);
    }
  };

  const handleResizeEvent = async ({ event }) => {};
  const handleDropEvent = async ({ event }) => {};
  const handleAddEvent = () => {
    setOpenModel(true);
    setEditData({});
  };

  const handleSyncEvent = async () => {
    const response = await AxiosAll('get', `/users/sync-events`, {}, token);
    if (response?.data) {
      enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } else {
      enqueueSnackbar(response?.data?.message, { variant: 'error' });
    }
  };

  const handleApplyLeave = () => {
    setOpenApply(true);
  };

  const handleCloseModal = () => {
    setOpenModel(false);
  };

  const handleToggle = (e, val) => {
    if (val === 'list') {
      setIsLogShow('list');
    } else if (val === 'calender') {
      setIsLogShow('calender');
    }
  };

  const getHoliday = async () => {
    const response = await AxiosAll('get', `/holiday`, {}, token);
    if (response?.status === 200) {
      const afterMap = response?.data?.data.map((value, index) => ({
        title: value?.type === 'leave' ? value?.modified_name : value?.name,
        allDay: value?.show,
        description: value?.description,
        end:
          value?.type === 'leave'
            ? new Date(value?.end_date).setDate(new Date(value?.end_date).getDate() + 1)
            : new Date(value?.end_date).toISOString(),
        id: value?.id,
        start: value?.start_date,
        textColor: value?.color,
        type: value?.type,
        lead: value?.team_lead,
        user: value?.applied_by_user,
        modified: value?.name,
        status: value?.status,
        start_date_time: new Date(value?.start_date).toISOString(),
        end_date_time: new Date(value?.end_date).toISOString(),
        users: value?.meeting_users || [],
        creator: value?.creator_user_id || '',
        comment: value?.comment,
      }));
      setEvents(afterMap);
      getCounts();
    }
  };
  const getCounts = async () => {
    const response = await AxiosAll('get', '/counts', {}, token);
    const { data } = response;

    dispatch(setCount(data));
  };
  const getColor = async () => {
    const response = await AxiosAll('get', `/holiday/colors`, {}, token);
    if (response?.status === 200) {
      setColorData(response?.data?.data);
    }
  };
  const refreshToken = async () => {
    const response = await AxiosAll('post', `/refresh-token`, {}, token);
    if (response?.status === 200) {
      checkToken();
    } else {
      setIsToken(true);
    }
  };
  const checkToken = async () => {
    const response = await AxiosAll('get', `/check-token`, {}, token);
    if (response?.data?.statusCode === 404 && response?.data?.expired) {
      setIsToken(true);
    } else if (response?.data?.statusCode === 200 && response?.data?.expired) {
      refreshToken();
    } else {
      setIsToken(false);
    }
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const getleaveData = async () => {
    const response = await AxiosAll('get', `/leave?startDate=${startDate}&endDate=${endDate}&userId=`, {}, token);
    if (response?.data?.statusCode === 200) {
      setLeaves(response?.data?.data);
      console.log('data', response?.data?.data);
    }
  };
  const handleRowClick = (id) => {
    navigate(`/leaveslist/${id}`, { state: { endDate, startDate } });
    setIsRedirected(true);
  };

  const handlelistClick = () => {
    navigate(`/leaveslist/${userDetails?.user?.id}`, { state: { endDate, startDate } });
    setIsRedirected(true);
  };

  console.log('date', endDate);
  console.log('startdate', startDate);

  useEffect(() => {
    getleaveData();
  }, [startDate, endDate]);

  useEffect(() => {
    getHoliday();
    getColor();
    checkToken();
  }, []);

  return (
    <Page title="Calendar">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading={isLogShow === 'calender' ? 'Calendar' : 'Leaves'}
          links={
            isLogShow === 'calender'
              ? [{ name: 'Tasks', href: '/kanban' }, { name: 'Calendar' }]
              : [{ name: 'Tasks', href: '/kanban' }, { name: 'Leaves' }]
          }
          action={
            isAdmin ? (
              <>
                <Button
                  Button
                  variant="contained"
                  startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
                  onClick={handleAddEvent}
                >
                  New Event
                </Button>
                {isToken ? (
                  <GoogleSignInButton checkToken={checkToken} />
                ) : (
                  <Button
                    Button
                    variant="contained"
                    sx={{ ml: '0.5rem' }}
                    onClick={() => {
                      setOpenAddMeet(true);
                      setMeetData({});
                    }}
                  >
                    Add Meeting
                  </Button>
                )}
                <Button
                  Button
                  variant="contained"
                  // startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
                  onClick={handleSyncEvent}
                >
                  Sync Event
                </Button>
                <ToggleButtonGroup
                  color="primary"
                  value={isLogShow}
                  exclusive
                  onChange={handleToggle}
                  aria-label="Platform"
                >
                  <ToggleButton value="list">List View</ToggleButton>
                  <ToggleButton value="calender">Calender</ToggleButton>
                </ToggleButtonGroup>
              </>
            ) : isTeam ? (
              <>
                <Button Button variant="contained" onClick={handleApplyLeave}>
                  Apply Leave
                </Button>
                <Button
                  Button
                  variant="contained"
                  sx={{ ml: '0.5rem' }}
                  onClick={() => {
                    if (isToken) {
                      enqueueSnackbar('Please contact admin for Sign in', { variant: 'error' });
                    } else {
                      setOpenAddMeet(true);
                      setMeetData({});
                    }
                  }}
                >
                  Add Meeting
                </Button>
                <ToggleButtonGroup
                  color="primary"
                  value={isLogShow}
                  exclusive
                  onChange={handleToggle}
                  aria-label="Platform"
                >
                  {/* <ToggleButton value="list">List View</ToggleButton> */}
                  <ToggleButton value="calender">Calender</ToggleButton>
                </ToggleButtonGroup>
              </>
            ) : (
              <>
                <Button Button variant="contained" onClick={handleApplyLeave}>
                  Apply Leave
                </Button>
                <ToggleButtonGroup
                  color="primary"
                  value={isLogShow}
                  exclusive
                  onChange={handleToggle}
                  aria-label="Platform"
                >
                  {/* <ToggleButton onClick={() => handlelistClick()}>List View</ToggleButton> */}
                  <ToggleButton value="calender">Calender</ToggleButton>
                </ToggleButtonGroup>
              </>
            )
          }
        />
        <Card>
          <ApplyDialogue
            open={openApply}
            onClose={() => setOpenApply(false)}
            getHoliday={getHoliday}
            isAdmin={isAdmin}
          />
          <AddMeeting
            open={openAddMeet}
            setOpenAddMeet={setOpenAddMeet}
            getHoliday={getHoliday}
            meetData={meetData}
            editMeet={editMeet}
          />
          {isLogShow === 'calender' ? (
            <Card>
              <CalendarStyle>
                <CalendarToolbar
                  date={date}
                  view={view}
                  onNextDate={handleClickDateNext}
                  onPrevDate={handleClickDatePrev}
                  onToday={handleClickToday}
                  onChangeView={setView}
                />
                <FullCalendar
                  weekends
                  editable
                  droppable
                  selectable
                  events={events}
                  ref={calendarRef}
                  rerenderDelay={10}
                  allDayMaintainDuration
                  initialDate={date}
                  initialView={view}
                  eventDisplay="block"
                  dayMaxEventRows={3}
                  select={handleSelectRange}
                  eventResize={handleResizeEvent}
                  eventDrop={handleDropEvent}
                  eventClick={handleSelectEvent}
                  height={isDesktop ? 720 : 'auto'}
                  plugins={[listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={false}
                />
              </CalendarStyle>
            </Card>
          ) : (
            <>
              <Grid container spacing={2} mt={3} sx={{ paddingLeft: '20px', paddingRight: '15px' }}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="start-date"
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="end-date"
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                  {isRedirected && <LeaveListDetail />}
                </Grid>
              </Grid>

              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Profile</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Sick Leaves</TableCell>
                      <TableCell>Festival Leaves</TableCell>
                      <TableCell>Other Leaves</TableCell>
                      <TableCell>Compensated Days</TableCell>
                      <TableCell>Total Leaves</TableCell>
                    </TableRow>
                  </TableHead>
                  {leaves?.length > 0 ? (
                    <>
                      <TableBody>
                        {leaves?.name}
                        {leaves?.map((leave) => {
                          const otherLeaves = leave?.halfDay / 2 + leave?.fullDay;
                          const sickLeaves = leave?.sickHalfDay / 2 + leave?.sickFullDay;
                          const festivalLeaves = leave?.festivalHalfDay / 2 + leave?.festivalFullDay;

                          const totalLeaves = otherLeaves - leave?.compensated;
                          return (
                            <TableRow
                              key={leave?.id}
                              onClick={() => handleRowClick(leave?.userId)}
                              style={{ cursor: 'pointer' }}
                            >
                              <TableCell>
                                <img
                                  src={leave?.profile_picture}
                                  alt={`${leave?.name}'s profile`}
                                  style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                />
                              </TableCell>
                              <TableCell>{leave?.name}</TableCell>
                              <TableCell>{sickLeaves || 0}</TableCell>
                              <TableCell>{festivalLeaves || 0}</TableCell>
                              <TableCell>{otherLeaves || 0}</TableCell>
                              <TableCell>{leave?.compensated || 0}</TableCell>
                              <TableCell>{totalLeaves || 0}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <EmptyContent
                            title="No Data"
                            sx={{
                              '& span.MuiBox-root': { height: 160 },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </>
          )}
        </Card>

        {isLogShow === 'calender' ? (
          <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} xs={10}>
            {colorData &&
              colorData?.map((item, index) => (
                <Grid key={index} style={{ display: 'flex', marginLeft: '1.5rem' }} className="graphdots">
                  <Item>{item?.name} &nbsp; :</Item>

                  <Item style={{ backgroundColor: item?.color, width: '15px', height: '15px' }}> </Item>
                </Grid>
              ))}
          </Grid>
        ) : null}
        <DialogAnimate open={openModel} onClose={handleCloseModal}>
          <DialogTitle>{'Add Event'}</DialogTitle>
          <CalendarForm event={editData} onCancel={handleCloseModal} getHoliday={getHoliday} isAdmin={isAdmin} />
        </DialogAnimate>
      </Container>
    </Page>
  );
}
